<template>
    <router-view />
</template>
<script>
export default {
  name: 'LandingLayout',

  data () {
    return {

    };
  },

  mounted () {

  },

  methods: {

  }
};
</script>
<style scoped></style>
